import React from 'react'
import PropTypes from "prop-types";
import {graphql} from 'gatsby'
import {getImage} from "gatsby-plugin-image";

import Layout from '../components/layout'
import Seo from '../components/seo'

import {PanelGroup, Panel, SectionContainer, Button} from "../components/primitives";
import {ExpenseList, HighlightSection, VideoHeader, FeatureRow, CallToAction} from "../components/composites";
import {slugify, useTransformHTML} from "../components/utils";

import './index.scss'


const RootIndex = ({data, location}) => {
    const {
        allContentfulMainPage
    } = data

    const page = allContentfulMainPage?.edges[0]?.node ?? {};
    const introDetails = page?.introDetails?.introDetails ?? '';
    const checklists = page?.expenseLists ?? [];
    const destinationOne = page?.expenseLists[0]?.destination ?? '';
    const destinationTwo = page?.expenseLists[1]?.destination ?? '';
    const highlightedTrips = page?.tripHighlights ?? [];
    const topCategories = page?.topCategories?.categories ?? [];
    const howItWorksPanel = page?.howItWorksSection ?? [];
    const howItWorksDescription = page?.howItWorksDescriptionLong?.howItWorksDescriptionLong ?? '';
    const topCategoriesDescription = page?.topCategoriesDescription?.topCategoriesDescription ?? '';
    const aboutMeDescription = useTransformHTML(page?.aboutMeDescriptionLong?.raw) ?? '';
    const aboutMeImage = page?.aboutMeImage?.gatsbyImageData ?? '';
    const aboutMeTitle = page?.aboutMeTitle ?? '';
    const callToActionButtonText = page?.callToActionButtonText ?? '';
    const callToActionText = page?.callToActionText ?? '';
    const callToActionImage = page?.callToActionImage ?? '';
    const videoURL = page?.headerVideo?.file.url ?? '';

    return (
        <Layout className='landing-page' location={location}>
            <Seo title='Home'/>

            <VideoHeader title='Do Something Different' alt='Man on Mountain' url={videoURL}/>

            {/*  Highlights  */}
            <SectionContainer className='highlights' bgColor='white'>
                <h2 className='page-welcome'>Where do you want to go?</h2>
                <p className='section-description welcome-description'>{introDetails}</p>

                {highlightedTrips.map(({
                    bgColor,
                                           ctaBG,
                                           cost,
                                           featureImage,
                                           days,
                                           description,
                                           destination,
                                           additionalImages,
                                           nights
                                       }, index) => (
                    <HighlightSection key={`${slugify(destination)}-highlight-section`} cost={cost} days={days} description={description} destination={destination} ctaBG={ctaBG}
                                      featureImage={featureImage} images={additionalImages} nights={nights}
                                      reverse={index === 1} bgColor={bgColor}/>
                ))}
            </SectionContainer>

            <SectionContainer className='how-it-works-section' bgColor='#f8faff'>
                <div className='overlay'>
                    <div className='content'>
                        <h2>How It Works</h2>
                        <p className='section-description'>{howItWorksDescription}</p>
                        <PanelGroup textAlign='left' divider>
                            {howItWorksPanel.map(({description, title}) => (
                                <Panel key={`${slugify(title)}-how-it-works`} description={description.description} title={title}/>
                            ))}
                        </PanelGroup>
                        <Button type='button' label='Get In Touch' href='/contact' tag='a'/>
                    </div>
                </div>

            </SectionContainer>

            {/*  Compare  */}
            <SectionContainer className='compare-section' bgColor='#fcfcfc'>
                <h2 className='section-header'>{destinationOne} Vs {destinationTwo} </h2>
                <p className='section-description'>Traveling internationally doesn't have to cost more. Take a look at these two receipts from actual bachelorette trips taken in the last year. </p>

                <div className='expense-list-grid'>
                    {checklists.map(({backgroundImage, days, destination, expenseItems, nights}) => (
                        <ExpenseList days={days} expenses={expenseItems} destination={destination} nights={nights}
                                     bgImage={backgroundImage} key={`${slugify(destination)}-expense-list`}/>
                    ))}
                </div>
            </SectionContainer>

            <SectionContainer className='top-categories-section' bgColor='#f8faff'>
                <h2>Top Categories</h2>
                <p className='section-description'>{topCategoriesDescription}</p>

                <PanelGroup stretch>
                    {topCategories.map( ({description, icon, name}) => (
                        <Panel description={''} icon={getImage(icon.gatsbyImageData)} title={name} titleTag='h3' key={`${slugify(name)}-top-categories`} />
                    ))}
                </PanelGroup>
            </SectionContainer>

            <SectionContainer className='about-me-section' bgColor='white'>
                <FeatureRow
                    alt={''}
                    header={aboutMeTitle}
                    description={aboutMeDescription}
                    image={aboutMeImage}
                    reverse
                    circle
                    textAlign='left'
                />
            </SectionContainer>

            <CallToAction title={callToActionText} btnText={callToActionButtonText} bgImage={callToActionImage}/>
        </Layout>
    )
}

RootIndex.propTypes = {
    data: PropTypes.shape({
        allContentfulMainPage: PropTypes.object
    }),
    location: PropTypes.object
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulMainPage {
      edges {
        node {
            headerVideo {
              file {
                url
              }
            }
           callToActionButtonText
           callToActionText
           callToActionImage {
            gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
            )
            title
           }
           introDetails {
            introDetails
           }
           expenseLists {
            backgroundImage {
              gatsbyImageData(
                  width: 645
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
              )
            }
            days
            destination
            expenseItems {
              cost
              name
            }
            nights
          }
          tripHighlights {
              description {
                raw
              }
              destination
              featureImage {
                gatsbyImageData(
                  width: 700
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              id
              bgColor
              ctaBG
              nights
              days
              cost
              additionalImages {
                gatsbyImageData(
                  width: 350
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            topCategories {
             categories {
               name
               icon {
                gatsbyImageData(
                  width: 65
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              } 
            }
          }
          topCategoriesDescription {
            topCategoriesDescription
          }
          howItWorksDescriptionLong {
            howItWorksDescriptionLong
          }
          aboutMeDescriptionLong {
              raw
          }
          aboutMeImage {
           gatsbyImageData(
                  width: 490
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
              )
          }
          aboutMeTitle
          aboutMeDescriptionLong {
              raw
          }
          howItWorksSection {
              title
              description {
                description
              }
            }
        }
      }
    }
  }
`
